import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import { useTranslations } from "@/i18n";
import type { CatalogLocals } from "@/types";
import { useStore } from "@nanostores/react";
import { $quickView, $quickViewOpen } from "./quick-view.store";
import { useMediaQuery } from "@/hooks/use-media-query";
import { addProductToCart } from "@/features/cart/cart.store";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { formatCurrency } from "@/lib/format-currency";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { cn } from "@/lib/utils";
import { toast } from "sonner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@/components/ui/table";
import type { CatalogProduct } from "../types";
import { ProductGroupOptions } from "../shared/ProductGroupOptions";
import { ImageWithFallback } from "@/components/ImageWithFallback";
import { usePreparedProduct } from "@/hooks/use-prepared-product";
import { SelectQuantity } from "@/features/shared/SelectQuantity";
import { SelectUnitType } from "@/features/shared/SelectUnitType";
import { OffersTable } from "../offers/Offers";
import { ProductPrice } from "../shared/ProductPrice";
import { formatDate } from "@/lib/format-date";

export function QuickView({ locals }: { locals: CatalogLocals }) {
  const isDesktop = useMediaQuery("(min-width: 480px)");

  const open = useStore($quickViewOpen);
  const product = useStore($quickView);

  const title = product?.name;

  if (isDesktop) {
    return (
      <Dialog open={!!open} onOpenChange={$quickViewOpen.set}>
        <DialogContent
          className="sm:max-w-screen-lg p-0"
          aria-describedby={undefined}
        >
          <VisuallyHidden>
            <DialogHeader>
              <DialogTitle>{title}</DialogTitle>
            </DialogHeader>
          </VisuallyHidden>

          {product && <QuickViewContent product={product} locals={locals} />}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={!!open} onOpenChange={$quickViewOpen.set}>
      <DrawerContent aria-describedby={undefined}>
        <VisuallyHidden>
          <DrawerHeader className="text-left">
            <DrawerTitle>{title}</DrawerTitle>
          </DrawerHeader>
        </VisuallyHidden>

        {product && <QuickViewContent product={product} locals={locals} />}
      </DrawerContent>
    </Drawer>
  );
}

const QuickViewContent = ({
  product,
  locals: { lang, supplier },
}: {
  locals: CatalogLocals;
  product: CatalogProduct;
}) => {
  const t = useTranslations(lang);
  const preparedProduct = usePreparedProduct({ product });

  return (
    <div className="flex flex-col gap-2 sm:gap-4 max-h-[95svh]">
      <div data-vaul-no-drag className="max-sm:overflow-y-auto">
        <div
          className={cn(
            "grid grid-cols-1 gap-1 p-2 ",
            "sm:grid-cols-[1fr_2fr] sm:gap-x-6 sm:p-4 sm:pb-0",
            "md:grid-cols-2",
          )}
        >
          <Carousel opts={{ loop: true }} className="max-sm:mb-2 sm:my-8">
            <CarouselContent>
              {product.carouselItems.map((item) => (
                <CarouselItem key={item.src}>
                  {item.mimeType.startsWith("image") ? (
                    <ImageWithFallback
                      src={item.src}
                      alt={product.name}
                      className="w-full aspect-square object-cover"
                    />
                  ) : (
                    <video
                      src={item.src}
                      className="w-full aspect-square"
                      controls
                    />
                  )}
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>

          <div className="sm:overflow-y-auto sm:h-0 sm:min-h-full flex flex-col gap-1">
            <h2 className="text-one font-semibold text-lg">
              <a
                href={`/${lang}/products/${product.slug}`}
                onClick={() => $quickViewOpen.set(false)}
              >
                {product.name}
              </a>
            </h2>

            <p className="text-sm text-two capitalize">
              {Object.entries(product.options)
                .map(([k, v]) => `${k} ${v}`)
                .join(" | ")}
            </p>

            <p className="text-one text-lg">
              <ProductPrice
                product={product}
                unitType={preparedProduct.unitType}
                lang={lang}
                country={supplier.countryCode}
              />
            </p>

            <ProductGroupOptions
              product={product}
              lang={lang}
              onSelect={({ target }) => target && $quickView.set(target)}
              supplier={supplier}
              className="my-2"
            />

            <div className="flex gap-2 items-center max-w-[90%]">
              {t("common.quantity")}:
              <SelectQuantity
                value={preparedProduct.quantity}
                onValueChange={preparedProduct.setQuantity}
                max={preparedProduct.stock}
                decimals={product.partialUnitsAllowed}
                triggerClassName="w-20 flex gap-2"
                disabled={!preparedProduct.addable}
              />
              {preparedProduct.shouldShowUnitTypePicker && (
                <SelectUnitType
                  value={preparedProduct.unitTypeName}
                  onValueChange={preparedProduct.setUnitTypeViaName}
                  triggerClassName="w-full"
                  choices={product.unitTypes}
                />
              )}
            </div>

            <Accordion
              defaultValue={["offers", "description", "details", "attributes"]}
              type="multiple"
            >
              {product.hasOffer && (
                <AccordionItem value="offers">
                  <AccordionTrigger>{t("offers.title")}</AccordionTrigger>
                  <AccordionContent>
                    <OffersTable
                      product={product}
                      lang={lang}
                      supplier={supplier}
                    />
                  </AccordionContent>
                </AccordionItem>
              )}

              {product.description && (
                <AccordionItem value="description">
                  <AccordionTrigger>{t("common.description")}</AccordionTrigger>
                  <AccordionContent>
                    <p>{product.description}</p>
                  </AccordionContent>
                </AccordionItem>
              )}

              <AccordionItem value="details">
                <AccordionTrigger>{t("common.details")}</AccordionTrigger>
                <AccordionContent>
                  <Table className="md:[&_th]:h-auto md:[&_td]:p-2">
                    <TableBody>
                      {product.details.unitPrice && (
                        <TableRow>
                          <TableHead>
                            {t("products.fields.unitPrice")}
                          </TableHead>
                          <TableCell>{product.details.unitPrice}</TableCell>
                        </TableRow>
                      )}
                      {product.details.upc && (
                        <TableRow>
                          <TableHead>{t("products.fields.upc")}</TableHead>
                          <TableCell>{product.details.upc}</TableCell>
                        </TableRow>
                      )}
                      {product.details.dueDate && (
                        <TableRow>
                          <TableHead>{t("products.fields.dueDate")}</TableHead>
                          <TableCell>
                            {formatDate(product.details.dueDate, lang)}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </AccordionContent>
              </AccordionItem>

              {Object.entries(product.attributes).length > 0 && (
                <AccordionItem value="attributes">
                  <AccordionTrigger>{t("common.attributes")}</AccordionTrigger>
                  <AccordionContent>
                    <Table className="md:[&_th]:h-auto md:[&_td]:p-2">
                      <TableBody>
                        {Object.entries(product.attributes).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableHead>{key}</TableHead>
                              <TableCell>{value}</TableCell>
                            </TableRow>
                          ),
                        )}
                      </TableBody>
                    </Table>
                  </AccordionContent>
                </AccordionItem>
              )}
            </Accordion>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-[1fr_2fr] sm:grid-cols-2 gap-2 sm:gap-x-6 p-2 sm:p-4 sm:pt-0">
        <Button
          variant="secondary"
          size="lg"
          onClick={() => $quickViewOpen.set(false)}
        >
          {t("common.close")}
        </Button>

        <Button
          variant="one"
          size="lg"
          disabled={!preparedProduct.addable}
          onClick={() => {
            $quickViewOpen.set(false);
            const isCheckoutPage = location.pathname.endsWith("/checkout");
            addProductToCart(preparedProduct.addableItem, !isCheckoutPage);
            if (isCheckoutPage) {
              toast.info(`${product.name} ${t("common.added").toLowerCase()}`);
            }
          }}
        >
          {preparedProduct.stock <= 0
            ? t("products.filters.stock.no")
            : `${t("common.add")} - ${formatCurrency(preparedProduct.addableAmount, lang, supplier.countryCode)}`}
        </Button>
      </div>
    </div>
  );
};
